<template>
  <div class="change-pricelist-block">
    <pricelist-block :list="list" grid="3">
      <template v-slot:item="{ item }">
        <pricelist-card
          :id="item.id"
          :title="item.name"
          :cloud="true"
          :list="getSpecs(item)"
          :active="current === item.id"
          @click="setCurrent(item)"
        />
        <!--          :price="item.cost"-->
      </template>
    </pricelist-block>
  </div>
</template>

<script>
import PricelistBlock from '@/components/Configurator/components/PricelistBlock';
import PricelistCard from '@/components/Configurator/components/PricelistCard';
export default {
  name: 'ChangePricelistBlock',
  components: { PricelistBlock, PricelistCard },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    // newFeatures: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      current: null,
    };
  },
  methods: {
    setCurrent(item) {
      if (this.current !== item.id) this.current = item.id;
      else this.current = null;
      // this.$emit('change', this.current, this.newFeatures);
      this.$emit('change', this.current);
    },
    getSpecs(item) {
      const list = [];
      for (let prop of Object.keys(item.specs)) {
        list.push(this.$t(`specs.${prop}`, { n: item.specs[prop] }));
      }
      return list;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    }
  }
}
</i18n>
